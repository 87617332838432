import classNames from 'classnames';
import React from 'react';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';
import './Loading.scss';
import { LoadingSpinner } from '../../../../../v5/design-sytem/LoadingSpinner';
import { useTranslation } from 'react-i18next';

export interface LoadingProps {
  className?: string;
  text?: string;
  light?: boolean;
  /**
   * @deprecated use textPlaceholder instead
   */
  options?: {
    disableText?: boolean;
  };
  layout?: 'default' | 'fullscreen';
  textPlacement?: 'center' | 'none';
}

export function Loading({
  className,
  text,
  light,
  options = {},
  layout = 'default',
  textPlacement
}: LoadingProps) {
  const { t } = useTranslation('v1_shared');

  // Determine if text should be disabled based on textPlacement or options.disableText
  const disableText =
    textPlacement === undefined
      ? options.disableText
      : textPlacement === 'none';

  return (
    <div
      data-id="Loading"
      className={classNames(
        'Loading tailwind-root',
        { default: '', fullscreen: 'pageCenter' }[layout],
        className
      )}
    >
      <div className="Loading-container animate-in">
        <div className="Loading-spinner">
          <LoadingSpinner
            color={light ? SYSTEM_COLOURS.WHITE : SYSTEM_COLOURS.BLACK}
            className="size-[30px]"
          />
        </div>
        {/* Display the provided text or fallback to the translated "Just a moment" */}
        {!disableText && <h5>{text || t('Loading.justAMoment')}</h5>}
      </div>
    </div>
  );
}

export default Loading;
