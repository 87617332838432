import { useState } from 'react';
import { releaseFlags } from '../releaseFlags.config';

export type ReleaseFlags = ReturnType<typeof createReleaseFlags>;

export type ReleaseFlag = keyof typeof releaseFlags;

export function createReleaseFlags() {
  function processReleaseFlag(flag: string) {
    const existingStorage = localStorage.getItem('release.' + flag);

    // the storage has a value in format cleanupId:flag, ie. 1:old
    if (existingStorage) {
      const [cleanupId] = existingStorage.split(':');
      if (cleanupId !== releaseFlags[flag].cleanupId) {
        localStorage.removeItem('release.' + flag);
      }
    }
  }

  Object.keys(releaseFlags).forEach(processReleaseFlag);

  function getReleaseFlag<T extends ReleaseFlag>(flag: T) {
    const existingStorage = localStorage.getItem('release.' + flag);
    if (!existingStorage) {
      return releaseFlags[flag].default;
    }
    const [, version] = existingStorage.split(':');
    if (releaseFlags[flag].versions.includes(version)) {
      return version;
    }
    return releaseFlags[flag].default;
  }

  return {
    getReleaseVersion: getReleaseFlag,
    useReleaseVersion: <T extends ReleaseFlag>(
      flag: T
    ): {
      version: (typeof releaseFlags)[T]['versions'][number];
      changeVersion: (
        version: (typeof releaseFlags)[T]['versions'][number]
      ) => void;
    } => {
      const [version, setVersion] = useState(() => getReleaseFlag(flag));
      return {
        version,
        changeVersion: version => {
          setVersion(version);
          localStorage.setItem(
            'release.' + flag,
            `${releaseFlags[flag].cleanupId}:${version}`
          );
        }
      };
    }
  };
}
