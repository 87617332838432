import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, get, has, map } from 'lodash';
import { convertToWholeUnit } from 'v1/helpers/currencyHelper';
import { isoToFriendlyDate } from 'v1/helpers/byType/dateHelper';
import { withTranslation } from 'react-i18next';
import { inputVariants } from 'v5/design-sytem/Input.js';
import { cn } from 'v5/platform/dom/cn';
import { XIcon } from 'lucide-react';
import { getSiblings } from 'v5/platform/dom/getSiblings.js';
import { getFocusableSelector } from 'v5/platform/dom/isFocusable.js';
import { focusRings } from '../../../../../../../v5/design-sytem/_common/focusRing.js';
import { inputGroupVariants } from '../../../../../../../v5/design-sytem/InputGroup.js';
import { unitFieldUtils } from '../../../../../../../v4/shared/components/forms/input/unit-field-utils.js';

export class SearchFilterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customObject: props.customObject
    };
  }
  onRemove = () => this.props.onRemove(this.props.fieldKey, null, null);
  renderKey = () => {
    const { customObject } = this.state;
    const { fieldKey } = this.props;
    if (customObject) {
      return customObject.name;
    }
    return this.props.t(`core:core_fields.${fieldKey}`, fieldKey);
  };
  renderMethodLabel = (methods, filter) => {
    const { fieldKey } = this.props;
    return Object.keys(methods).map(method => {
      if (filter[method]) {
        let value;
        switch (fieldKey) {
          case 'rates':
            value = parseInt(convertToWholeUnit(filter[method]));
            break;
          case 'tags':
            value = filter[method].join(', ');
            break;
          case 'groups':
            value = filter[method]
              .map(group => get(this.props.groups, ['data', group, 'name']))
              .join(', ');
            break;
          default:
            value = filter[method];
        }

        if (unitFieldUtils.isUnitValue(value)) {
          value = unitFieldUtils.formatDisplay(value);
        }

        return `${methods[method]} ${value}`;
      } else {
        return null;
      }
    });
  };

  renderValue = () => {
    const { customObject } = this.state;
    const { t, filter, fieldKey } = this.props;
    let value;

    if (fieldKey === 'resource_type_id') {
      return get(this.props.resourceTypes, ['data', filter.eq, 'name']);
    }

    if (fieldKey === 'availability') {
      return isoToFriendlyDate(
        { date1: filter.from_date, date2: filter.to_date },
        'DEFAULT_RANGE',
        'SHORT'
      );
    }

    if (fieldKey === 'tags') {
      const method = has(filter, 'match') ? 'match' : 'any';
      return (value = map(get(filter, method), t =>
        typeof t === 'string' ? t : t.tag
      )).join(', ');
    }

    switch (filter.custom_field_definition_type) {
      case 'SINGLE_SELECT':
        if (filter && filter.eq && Array.isArray(filter.eq)) {
          value = filter.eq.map(i => i).join(', ');
        }
        if (filter && filter.eq && typeof filter.eq === 'string') {
          value = filter.eq;
        }
        break;
      case 'CURRENCY':
        value = this.renderMethodLabel(
          {
            eq: t('SearchField.searchTypes.CURRENCY.eq'),
            gte: t('SearchField.searchTypes.CURRENCY.gte'),
            lte: t('SearchField.searchTypes.CURRENCY.lte')
          },
          filter
        );
        break;
      case 'NUMBER':
        value = this.renderMethodLabel(
          {
            eq: t('SearchField.searchTypes.NUMBER.eq'),
            gte: t('SearchField.searchTypes.NUMBER.gte'),
            lte: t('SearchField.searchTypes.NUMBER.lte')
          },
          filter
        );
        break;
      case 'MULTI_SELECT':
        const method = has(filter, 'match') ? 'match' : 'any';
        const selected = filter[method].map(v => {
          // TODO: we need to decide on the type of v to avoid type checks like the one below
          let value;

          if (typeof v === 'string') {
            value = v;
          } else if (typeof v === 'object') {
            value = v.name;
          } else {
            value = v.tag;
          }

          const option = find(customObject.options, o => o.value === value); // TODO: Need to make cleaner currently listening to tag input with .tag
          return option && option.value;
        });
        value = selected.join(', ');
        break;
      default:
        value = this.renderMethodLabel(
          {
            eq: '',
            gte: t('SearchField.searchTypes.NUMBER.gte'),
            lte: t('SearchField.searchTypes.NUMBER.lte'),
            gt: t('SearchField.searchTypes.NUMBER.gte'),
            lt: t('SearchField.searchTypes.NUMBER.lte'),
            match: t('SearchField.searchTypes.MULTISELECT.match')
          },
          filter
        );
    }

    return value;
  };
  render() {
    return (
      <div
        className={cn(
          inputGroupVariants({
            variant: 'regular',
            size: this.props.slimline ? 'sm' : 'md'
          }),
          'inline-flex p-0 w-fit relative group overflow-hidden cursor-default',
          focusRings.focus.visible
        )}
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Backspace') {
            this.onRemove();
            const siblings = getSiblings(
              e.currentTarget.parentElement.parentElement,
              e.target,
              getFocusableSelector()
            );
            requestAnimationFrame(() => {
              siblings?.prevElement?.focus?.();
            });
          }
        }}
      >
        <span
          className={cn(
            'bg-bg-subtle font-bold flex flex-row items-center',
            this.props.slimline ? 'px-1' : 'px-2'
          )}
        >
          {this.renderKey()}
        </span>
        <span className="px-2  font-bold overflow-hidden whitespace-no-wrap overflow-ellipsis flex flex-row items-center">
          {this.renderValue()}
        </span>
        {!this.props.lockedFields.includes(this.props.fieldKey) && (
          <div
            data-role="remove"
            className={cn(
              'flex items-center absolute top-0 right-0 bottom-0 bg-bg-default border-l border-border-default rounded-r transition-all group-hocus:opacity-100 opacity-0 py-1/2 px-1 cursor-pointer'
            )}
            onClick={this.onRemove}
          >
            <XIcon className="size-4 my-1" />
          </div>
        )}
      </div>
    );
  }
}

SearchFilterItem.defaultProps = {
  lockedFields: []
};

SearchFilterItem.propTypes = {
  slimline: PropTypes.bool,
  filter: PropTypes.object.isRequired, // TODO: properties: custom_field_definition_type, ?
  fieldKey: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  customObject: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.array
  }),
  lockedFields: PropTypes.array
};

const mapStateToProps = state => {
  return {
    resourceTypes: state.resource_types,
    groups: state.groups
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation(['v1_shared', 'core'])(SearchFilterItem));
