import { maybe } from '@passionware/monads';
import {
  CustomFieldDefinition,
  CustomFieldType,
  FeatureFlag
} from '../../../../__types__';
import { myServices } from '../../../services/services.connected.app';

export const fields = (
  [
    {
      label: 'Short Text',
      description: 'Good for short text values, like pet name or star sign',
      type: CustomFieldType.SHORT_TEXT,
      icon: '/images/icon_field_short_text.svg'
    },
    {
      label: 'Long Text',
      description: 'Good for long form writing like the meaning of life',
      type: CustomFieldType.LONG_TEXT,
      icon: '/images/icon_field_short_text.svg'
    },
    {
      label: 'Url',
      description: 'Good for clickable links and URL formatting',
      type: CustomFieldType.URL,
      icon: '/images/icon_field_url.svg'
    },
    {
      label: 'Number',
      description: 'Restricts input to whole numbers only',
      type: CustomFieldType.NUMBER,
      icon: '/images/icon_field_number.svg'
    },
    {
      label: 'Percentage',
      description: 'Allow percentages and decimals in this input',
      type: CustomFieldType.PERCENTAGE,
      icon: '/images/icon_field_percentage.svg'
    },
    {
      label: 'Boolean (Yes / No)',
      description: 'Checkbox to store simple yes / no data',
      type: CustomFieldType.BOOLEAN,
      icon: '/images/icon_field_boolean.svg'
    },
    {
      label: 'Date',
      description: 'Select dates within a calendar',
      type: CustomFieldType.DATE,
      icon: '/images/icon_field_date.svg'
    },
    {
      label: 'Date Range',
      description: 'Select a start and end date from a calendar',
      type: CustomFieldType.DATE_RANGE,
      icon: '/images/icon_field_date.svg'
    },
    {
      label: 'Dropdown (Single Choice)',
      description: "Good for one choice of defined values like 'Hair Colour'",
      type: CustomFieldType.SINGLE_SELECT,
      icon: '/images/icon_field_single_select.svg'
    },
    {
      label: 'Dropdown (Multiple Choice)',
      description: 'Good for multi selection of defined values',
      type: CustomFieldType.MULTI_SELECT,
      icon: '/images/icon_field_multi_select.svg'
    },
    {
      label: 'Currency',
      description: 'Good for storing currency, amount and unit',
      type: CustomFieldType.CURRENCY,
      icon: '/images/icon_field_currency.svg'
    },
    myServices.featureService.releaseFlags.getReleaseVersion(
      'new-measurement-field'
    ) === 'new'
      ? {
          label: 'Measurement',
          description: 'A measurement with ability to define unit',
          type: CustomFieldType.MEASUREMENT_NEW,
          icon: '/images/icon_field_measurement.svg'
        }
      : null,
    {
      label: 'Measurement (deprecated)',
      description: 'Please use the new Measurement field with unit',
      type: CustomFieldType.MEASUREMENT,
      icon: '/images/icon_field_measurement.svg',
      disabled: true
    },
    {
      label: '2D Dimension (deprecated)',
      description:
        'Allows you to capture 2D measurements such as length x width',
      type: CustomFieldType.DIMENSION_2D,
      icon: '/images/icon_field_dimension_2d.svg',
      disabled: true
    },
    {
      label: '3D Dimension (deprecated)',
      description:
        'Allows you to capture 3D measurements such as width x height x depth',
      type: CustomFieldType.DIMENSION_3D,
      icon: '/images/icon_field_dimension_3d.svg',
      disabled: true
    },
    {
      label: 'Height (deprecated)',
      description: 'Please use the new Measurement field with unit',
      type: CustomFieldType.HEIGHT,
      icon: '/images/icon_field_height.svg',
      disabled: true
    },
    {
      label: 'NetSuite Master Project',
      description: 'Allows you to capture a Netsuite Master Project',
      type: CustomFieldType.NETSUITE_MASTER_PROJECT,
      icon: '/images/icon_field_dimension_3d.svg',
      featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
    },
    {
      label: 'NetSuite Project Subclass',
      description: 'Allows you to capture a Netsuite Project subclass',
      type: CustomFieldType.NETSUITE_SUBCLASS,
      icon: '/images/icon_field_dimension_3d.svg',
      featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
    },
    {
      label: 'NetSuite Purchase Order',
      description: 'Allows you to capture a Netsuite Purchase Order number',
      type: CustomFieldType.NETSUITE_PO,
      icon: '/images/icon_field_dimension_3d.svg',
      featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
    },
    {
      label: 'NetSuite Vendor',
      description: 'Allows you to capture a Netsuite Vendor ID',
      type: CustomFieldType.NETSUITE_VENDOR,
      icon: '/images/icon_field_dimension_3d.svg',
      featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
    }
  ] satisfies Array<null | {
    type: CustomFieldDefinition['data_type'];
    label: string;
    description: string;
    icon: string;
    featureFlag?: FeatureFlag;
    disabled?: boolean;
  }>
).filter(maybe.isPresent);
