import { ReleaseFlagsBase } from './_private/releaseFlags.types';

export const releaseFlags = {
  frontdesk: {
    versions: ['old', 'new'],
    default: 'new',
    cleanupId: '1'
  },
  'production-calendar': {
    versions: ['old', 'new'],
    default: 'new',
    cleanupId: '1'
  },
  'new-measurement-field': {
    versions: ['none', 'new'],
    default: 'none',
    cleanupId: '1'
  },
  'locale-switcher': {
    versions: ['none', 'new'],
    default: 'none',
    cleanupId: '1'
  },
  'locale-settings': {
    versions: ['none', 'new'],
    default: 'none',
    cleanupId: '1'
  }
} satisfies ReleaseFlagsBase;
